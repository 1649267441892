import React from "react";
// PHONE NUMBER REACT
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// REDUX
import { useSelector } from "react-redux";
// INTERFACES
import { FormInputPhoneNumberInterface } from "../../../interfaces/Global.interface";

function FormInputPhoneNumber({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormInputPhoneNumberInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getDropDownThemeColor = () => {
		if (theme === "light") {
			return "#d9d9d9";
		}
		if (theme === "dark") {
			return "#2a2a2a";
		}
		if (theme === "blue") {
			return "#16314d";
		}
		return "";
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='drawer__text generalStyles__mlFix'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<PhoneInput
						country='us'
						enableAreaCodes
						value={inputValue}
						onChange={(event: any) => {
							inputOnChange(event, inputVariableName);
						}}
						// Set your inline styles here
						containerStyle={{
							borderColor: `${theme === "light" ? "#d9d9d9" : "#434343"}`,
						}}
						inputStyle={{
							background: "transparent",
							borderColor: `${theme === "light" ? "#d9d9d9" : "#434343"}`,
							color: `${theme === "light" ? "black" : "white"}`,
							width: "100%",
							height: "10px",
							borderRadius: "0px",
						}}
						dropdownStyle={{
							backgroundColor: `${getDropDownThemeColor()}`,
						}}
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputPhoneNumber;
