import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MonitoringHeartBeat from "../monitoring/MonitoringHeartBeat";
// INTERFACES
import { ControlPanelBankHeaderInterface } from "../../../interfaces/EdgeManagement.interface";

import { RootState } from "../../../app/store";

function ControlPanelBankHeader({ liftVip }: ControlPanelBankHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { bank } = useSelector((state: RootState) => state.bank);
	const { layoutSelected } = useSelector((state: RootState) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`montoringView__controlPanel__${theme} generalStyles__spaceBetween  `} style={{ padding: "20px" }}>
			<div className='generalStyles__flex' style={{ marginTop: "5px" }}>
				<MonitoringHeartBeat
					socketMessage={{
						bank_id: "",
						lift_id: "",
						lift_type: "",
						bank_name: "",
						lift_name: "",
						car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
						hall_calls: {
							up: {
								front: [],
								rear: [],
								front_lock: [],
								rear_lock: [],
							},
							down: {
								front: [],
								rear: [],
								front_lock: [],
								rear_lock: [],
							},
							up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
							down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
						},
						faults: false,
						position: 1,
						icon: {
							type: "",
							name: "",
							description: "",
						},
						service_mode: {
							name: "",
							description: "",
						},
						heartbeat: 0,
						direction: "",
					}}
				/>
				<div>
					<div>
						<h3>{layoutSelected && layoutSelected.nameOrganization ? layoutSelected.nameOrganization : ""}</h3>
					</div>
					<div>
						<h5 className='generalStyles__info' style={{ marginTop: "-15px" }}>
							{bank && bank.name ? bank.name : ""}
						</h5>
					</div>
				</div>
			</div>
			<div className='generalStyles__flex mt-2'>
				{liftVip && liftVip.id && liftVip.id !== "" && (
					<>
						<div>
							<h3>Car VIP:</h3>
						</div>
						<div className='generalStyles__mlFix'>
							<Tag
								color={liftVip && liftVip.name ? "gold" : "gray"}
								className='generalStyles__width100px generalStyles__textCenter mt-1'
							>
								{liftVip && liftVip.name ? liftVip.name : "--"}
							</Tag>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default ControlPanelBankHeader;
