import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// ANT DESIGN COMPONENT
import { Drawer, Avatar, Button, Tag, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faRightFromBracket, faWifi } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenDrawerUser,
	updateGlobalOrganizationName,
	updateGlobalRolName,
	updateGlobalOrganizationId,
	updateGlobalRolId,
} from "../../redux/home/homeSlice";
// COMPONENTS
import Appearance from "./Appearance";
import Internationalization from "./Internationalization";
import DrawerOrganizationInformation from "../components/drawer/DrawerOrganizationInformation";
import DrawerRoleInformation from "../components/drawer/DrawerRoleInformation";
import DrawerUserInformation from "../components/drawer/DrawerUserInformation";
import GlobalConfiguration from "./GlobalConfiguration";
import ButtonClose from "../components/buttons/ButtonClose";
// AUTHORIZATION
import validatePermission from "../../utils/validatePermissions";
// STYLES
import usaFlag from "../../assets/i18n/unitedStatesFlag.png";
import spainFlag from "../../assets/i18n/spainFlag.png";
// INTERFACES
import { RootState } from "../../app/store";

const YourProfileDrawer = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged, language, openDrawerUser, globalOrganizationName, globalRolName } = useSelector(
		(state: RootState) => state.home
	);
	const { socketConnection } = useSelector((state: RootState) => state.monitoring);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// FUNCTIONS *************************************** */
	const showDrawer = () => {
		dispatch(updateOpenDrawerUser(true));
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerUser(false));
	};

	const signOut = async () => {
		await Auth.signOut();
		localStorage.setItem("useLoggedAmplify", "false");
		window.location.href = `${process.env.REACT_APP_LOGIN_ROUTE}`;
	};

	// *********************************************** */
	// USE EFFECT ************************************ */
	useEffect(() => {
		if (userLogged) {
			if (userLogged.org && userLogged.org.name) {
				dispatch(updateGlobalOrganizationId(userLogged.org.id));
				dispatch(updateGlobalOrganizationName(userLogged.org.name));
			}
			if (userLogged.role && userLogged.role.name) {
				dispatch(updateGlobalRolId(userLogged.role.id));
				dispatch(updateGlobalRolName(userLogged.role.name));
			}
		}
	}, [userLogged]);

	// ************************************************* */
	// COMPONENT ************************************** */
	return (
		<div>
			<div
				className={isMobile ? "generalStyles__flex layout__userAvatarMobile " : "generalStyles__flex"}
				role='button'
				tabIndex={0}
				onClick={() => {
					showDrawer();
				}}
				onKeyDown={() => {
					showDrawer();
				}}
			>
				{/** ************************************************* */}
				{/** HEADER AND TAGS */}
				{!isMobile && (
					<div className='mt-2'>
						<Tooltip title={globalOrganizationName}>
							<Tag
								color='blue'
								className='generalStyles__width150px generalStyles__textCenter generalStyles__textEllipsis'
							>
								{globalOrganizationName || "--"}
							</Tag>
						</Tooltip>
						<Tooltip title={globalRolName}>
							<Tag
								color='purple'
								className='generalStyles__width150px generalStyles__textCenter generalStyles__textEllipsis'
							>
								{globalRolName || "--"}
							</Tag>
						</Tooltip>
						{socketConnection === "EDGE" && (
							<Tag
								color='green'
								className='generalStyles__width150px generalStyles__textCenter generalStyles__textEllipsis'
							>
								<FontAwesomeIcon icon={faWifi} className=' generalStyles__userLoggedIcon' />
								{"  "}Edge Server
							</Tag>
						)}
						{socketConnection === "OFFLINE" && (
							<Tag
								color='cyan'
								className='generalStyles__width150px generalStyles__textCenter generalStyles__textEllipsis'
							>
								<FontAwesomeIcon icon={faWifi} className=' generalStyles__userLoggedIcon' />
								{"  "}Offline Server
							</Tag>
						)}
						{socketConnection === "EMULATOR" && (
							<Tag
								color='orange'
								className='generalStyles__width150px generalStyles__textCenter generalStyles__textEllipsis'
							>
								<FontAwesomeIcon icon={faWifi} className=' generalStyles__userLoggedIcon' />
								{"  "}Emulator Server
							</Tag>
						)}
					</div>
				)}
				{/** ************************************************* */}
				{/** USER AVATAR */}
				<div className='generalStyles__mlFix25'>
					<Avatar className='button__button1'>
						{userLogged && userLogged.first_name ? userLogged.first_name.substring(0, 1) : ""}
						{userLogged && userLogged.last_name ? userLogged.last_name.substring(0, 1) : ""}
					</Avatar>
				</div>
				{/** ************************************************* */}
				{/** CURRENT LANGUAGE ICON */}
				{!isMobile && (
					<div>
						<img
							src={language === "en" ? usaFlag : spainFlag}
							alt=''
							width='25'
							height='15'
							className='generalStyles__mlFix'
						/>
					</div>
				)}
			</div>
			<Drawer
				width={550}
				placement='right'
				onClose={onClickClose}
				closable={false}
				open={openDrawerUser}
				footer={
					<div className='container generalStyles__flex'>
						<div className='generalStyles__width100 generalStyles__mrFix'>
							<ButtonClose onClick={onClickClose} />
						</div>
					</div>
				}
			>
				<div className='generalStyles__spaceBetween'>
					<div className='generalStyles__flex'>
						{/** ************************************************* */}
						{/** USER AVATAR */}
						<div>
							{!isMobile && (
								<Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}>
									{userLogged && userLogged.first_name ? userLogged.first_name.substring(0, 1) : ""}
									{userLogged && userLogged.last_name ? userLogged.last_name.substring(0, 1) : ""}
								</Avatar>
							)}
						</div>
						{/** ************************************************* */}
						{/** USER EMAIL AND ROLE DATA */}
						<div className='generalStyles__mlFix25 mt-3'>
							<p className='drawer__userNameDrawer'>
								{userLogged && userLogged.first_name ? userLogged.first_name : ""}{" "}
								{userLogged && userLogged.middle_name ? userLogged.middle_name : ""}{" "}
								{userLogged && userLogged.last_name ? userLogged.last_name : ""}
							</p>
							<p className='generalStyles__userLoggedEmail'>
								{userLogged && userLogged.email ? userLogged.email : "--"}
							</p>
							<p className='generalStyles__userLoggedEmail'>
								<FontAwesomeIcon
									icon={faCircleCheck}
									className='generalStyles__success generalStyles__userLoggedIcon'
								/>
								<span className='generalStyles__mlFix'>
									{" "}
									{userLogged && userLogged.role && userLogged.role.name ? userLogged.role.name : "--"}
								</span>
							</p>
						</div>
					</div>
					{/** ************************************************* */}
					{/** SIGN OUT BUTTON */}
					<div>
						<Button
							className='drawer__logoutButton'
							shape='round'
							icon={<FontAwesomeIcon icon={faRightFromBracket} className='generalStyles__mrFix' />}
							onClick={signOut}
						>
							{t("header.signOut")}
						</Button>
					</div>
				</div>
				{/** ************************************************* */}
				{userLogged && (
					<div>
						{/** ************************************************* */}
						{/** PASSWORD OFFLINE */}
						{/** <OfflinePsw /> */}
						{/** ************************************************* */}
						{/** GLOBAL CONFIGURATION */}
						{validatePermission("view-application-global-configuration", permissions) && <GlobalConfiguration />}
						{/** ************************************************* */}
						{/** INTERNATIONALIZATION */}
						<Internationalization />
						{/** ************************************************* */}
						{/** APPEARANCE */}
						<Appearance />
						{/** ************************************************* */}
						{/** USER INFORMATION */}
						<DrawerUserInformation data={userLogged || {}} />
						{/** ************************************************* */}
						{/** ROLE INFORMATION */}
						<DrawerRoleInformation data={userLogged.role || {}} />
						{/** ************************************************* */}
						{/** ORGANIZATION INFORMATION */}
						<DrawerOrganizationInformation data={userLogged.org || {}} />
					</div>
				)}
				<br />
			</Drawer>
		</div>
	);
};

export default YourProfileDrawer;
