import React, { useState, useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateExistsFaultsInMonitoringView } from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import { SocketMessageInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringLiftElevatorFullViewIcon({
	socketMessage,
	floor,
	lift,
	floorIndex,
	stops,
	elevatorFirstStateIcon,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [vipMode, setVipMode] = useState<boolean>(false);
	const [lastShortMessage, setLastShortMessage] = useState("");
	const [lastSocketMessage, setLastSocketMessage] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [lastPosition, setLastPosition] = useState<number>(1);
	const [validPosition, setValidPosition] = useState<boolean>(true);
	const [isFirstSocketMessage, setIsFirstSocketMessage] = useState<boolean>(true);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { elevatorSVGIcons, faults, iconDefinitionElevator } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getIcon = () => {
		let icon = "";
		// *********************************
		// CAR CALLS
		if (
			lastSocketMessage.car_calls.front.includes(floor.floor_no) ||
			lastSocketMessage.car_calls.rear.includes(floor.floor_no)
		) {
			icon = "elGoal";
		}
		// *********************************
		// HALL CALLS
		if (
			lastSocketMessage.hall_calls.up_assigned.rear.includes(floor.floor_no) ||
			lastSocketMessage.hall_calls.up_assigned.front.includes(floor.floor_no)
		) {
			icon = "elGoalGreen";
		}
		if (
			lastSocketMessage.hall_calls.down_assigned.front.includes(floor.floor_no) ||
			lastSocketMessage.hall_calls.down_assigned.rear.includes(floor.floor_no)
		) {
			icon = "elGoalRed";
		}
		// *********************************
		// LOCK CAR AND HALL CALLS
		if (
			lastSocketMessage &&
			lastSocketMessage.car_calls &&
			lastSocketMessage.car_calls.front_lock &&
			lastSocketMessage.car_calls.rear_lock &&
			lastSocketMessage.hall_calls &&
			lastSocketMessage.hall_calls.up_assigned &&
			lastSocketMessage.hall_calls.up_assigned.front_lock &&
			lastSocketMessage.hall_calls.up_assigned.rear_lock &&
			lastSocketMessage.hall_calls.down_assigned &&
			lastSocketMessage.hall_calls.down_assigned.front_lock &&
			lastSocketMessage.hall_calls.down_assigned.rear_lock
		) {
			if (
				lastSocketMessage.car_calls.front_lock.includes(floor.floor_no) ||
				lastSocketMessage.car_calls.rear_lock.includes(floor.floor_no) ||
				lastSocketMessage.hall_calls.up_assigned.front_lock.includes(floor.floor_no) ||
				lastSocketMessage.hall_calls.up_assigned.rear_lock.includes(floor.floor_no) ||
				lastSocketMessage.hall_calls.down_assigned.front_lock.includes(floor.floor_no) ||
				lastSocketMessage.hall_calls.down_assigned.rear_lock.includes(floor.floor_no)
			) {
				icon = "elLocked";
			}
		}
		// *********************************
		// ELEVATOR GLOBAL ICONS
		if (floor.floor_no === lastPosition) {
			icon = lastShortMessage;
			if (!validPosition) {
				icon = "elWarning";
			}
		}
		return <IconDefinitionIcons isMap={false} expanded={false} liftType='ELEVATOR' icon={icon} iconSize={20} />;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (socketMessage && socketMessage.lift_id && lift && lift.id === socketMessage.lift_id) {
			setLastSocketMessage(socketMessage);
			if (socketMessage.position > 0) {
				setLastPosition(socketMessage.position);
				setValidPosition(true);
			} else {
				setLastPosition(1);
				setValidPosition(false);
			}
		}
	}, [socketMessage]);

	useEffect(() => {
		if (
			iconDefinitionElevator &&
			elevatorSVGIcons.includes(iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()])
		) {
			setLastShortMessage(iconDefinitionElevator[lastSocketMessage.icon.name.toLowerCase()]);
		}
		// FAULT MESSAGES
		if (lastSocketMessage.icon.type.toUpperCase() === "FAULT" && lift && lift.id === lastSocketMessage.lift_id) {
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));
		}
		// If is first socket message when reload the page
		if (isFirstSocketMessage && lastSocketMessage.faults) {
			setHasFaults(true);
			setIsFirstSocketMessage(false);
			dispatch(updateExistsFaultsInMonitoringView(true));
		}
		if (lastSocketMessage.service_mode.name === "VIP") {
			setVipMode(true);
		} else {
			setVipMode(false);
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
		}
		if (faults && faults.type === "ALL") {
			setHasFaults(false);
		}
	}, [faults]);

	useEffect(() => {
		if (isFirstSocketMessage) {
			setLastShortMessage(elevatorFirstStateIcon);
		}
	}, [elevatorFirstStateIcon]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className={`monitoring__card__liftContainer__${theme} ${
				hasFaults ? "monitoringView__dangerElevator" : `${vipMode ? "monitoringView__vipElevator" : ""}`
			} ${
				hasFaults && floorIndex === 0
					? `monitoringView__dangerElevator__top `
					: `${vipMode ? "monitoringView__vipElevator__top" : ""}`
			}${
				hasFaults && floorIndex === stops - 1
					? `monitoringView__dangerElevator__bottom`
					: `${vipMode ? "monitoringView__vipElevator__bottom" : ""}`
			}  ${floorIndex === 0 ? "monitoring__card__liftContainerBorderTop " : ""} ${
				floorIndex === stops - 1 ? "monitoring__card__liftContainerBorderBottom" : ""
			}`}
		>
			{getIcon()}
		</div>
	);
}

export default MonitoringLiftElevatorFullViewIcon;
