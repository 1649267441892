import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Table, Empty, Tag, Select, Tooltip } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBankAction, updateFormAction } from "../../../redux/banks/bankSlice";
import { usePostActionMutation, usePutActionMutation } from "../../../redux/actions/actionsAPI";
// COMPONENTS
import BankActionsTableActions from "./BankActionsTableActions";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankActionInterface } from "../../../interfaces/Bank.interface";

function BankActionsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column, ColumnGroup } = Table;
	const { Option } = Select;
	const BLANK_ACTION = {
		id: "",
		button_type: "",
		action: "",
		toggle_action: "",
		color1: "",
		color2: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [actions, setActions] = useState<any[]>([]);
	const [action, setAction] = useState<BankActionInterface>(BLANK_ACTION);
	const [totalRows, setTotalRows] = useState<number>(0);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostAction,
		{
			isSuccess: isSuccessPostAction,
			isLoading: isLoadingPostAction,
			isError: isErrorPostAction,
			error: errorPostAction,
			reset: resetPostAction,
		},
	] = usePostActionMutation();

	const [
		triggerPutAction,
		{
			isSuccess: isSuccessPutAction,
			isLoading: isLoadingPutAction,
			isError: isErrorPutAction,
			error: errorPutAction,
			reset: resetPutAction,
		},
	] = usePutActionMutation();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);
	const { formAction, bank } = useSelector((state: RootState) => state.bank);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: BankActionInterface = structuredClone(action);
		copy[variableName] = newValue;
		setAction(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formAction === "create") {
			const BODY = {
				token,
				body: action,
			};
			triggerPostAction(BODY);
		} else {
			const copy = structuredClone(action);
			const BODY = {
				token,
				id: action.id,
				body: copy,
			};
			triggerPutAction(BODY);
		}
	};

	const resetForm = () => {
		resetPostAction();
		resetPutAction();
		setAction(BLANK_ACTION);
		dispatch(updateFormAction("create"));
		dispatch(updateBankAction(BLANK_ACTION));
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (action.cmd === "" || action.label === "" || action.tooltip === "") {
			formIsValid = false;
		}
		return formIsValid;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		resetForm();
	}, [isSuccessPostAction, isSuccessPutAction, isErrorPostAction, isErrorPutAction]);

	useEffect(() => {
		if (formAction === "create") {
			setAction(BLANK_ACTION);
		}
	}, [formAction]);

	useEffect(() => {
		if (bank && bank.actions) {
			setActions(bank.actions);
			setTotalRows(bank.actions.length);
		}
	}, [bank]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** ACTION FORM */}
			<div>
				{validatePermission("permission-not-defined", permissions) ? (
					<>
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							dataSource={actions}
							pagination={{
								defaultPageSize: 10,
								showSizeChanger: true,
								pageSizeOptions: ["10", "25", "50", "100"],
							}}
							size='small'
							className='mt-3'
							loading={isLoadingPostAction || isLoadingPutAction}
							footer={() => (
								<div className='generalStyles__flexEnd'>
									<Tag>{totalRows} Rows</Tag>
								</div>
							)}
						>
							{/** BUTTON TYPE */}
							<ColumnGroup title='Type'>
								<Column
									title={
										<Select
											showSearch
											optionFilterProp='children'
											className='generalStyles__width150px'
											allowClear={false}
											size='small'
											value={formAction === "create" ? action.button_type : ""}
											onChange={(e) => {
												onChangeFormValue(e, "type");
											}}
										>
											{["option 1", "option 2", "option 3"].map((item: any) => (
												<Option key={item} value={item}>
													{item}
												</Option>
											))}
										</Select>
									}
									dataIndex='type'
									key='type'
									render={(text, record: BankActionInterface) => (
										<div>
											{formAction === "edit" && record.id === action.id ? (
												<Select
													showSearch
													optionFilterProp='children'
													className='generalStyles__width100'
													allowClear={false}
													size='small'
													value={action.button_type}
													onChange={(e) => {
														onChangeFormValue(e, "type");
													}}
												>
													{["option 1", "option 2", "option 3"].map((item: any) => (
														<Option key={item.name} value={item.name}>
															{item.name}
														</Option>
													))}
												</Select>
											) : (
												<span>{text || "--"}</span>
											)}
										</div>
									)}
								/>
							</ColumnGroup>
							{/** ACTIONS */}
							<ColumnGroup align='center' title={t("edgeManagement.banks.actions")}>
								<Column
									align='center'
									title={
										<div className='generalStyles__flexCenter'>
											<div>
												<Tooltip title={t("general.save")}>
													<Button
														className={formAction === "create" && checkFormIsValid() ? "buttonStyle__10" : ""}
														disabled={!checkFormIsValid() || formAction === "edit"}
														onClick={onClickSave}
														icon={<FontAwesomeIcon icon={faSave} />}
													/>
												</Tooltip>
											</div>
											<div className='generalStyles__mlFix'>
												<Tooltip title={t("general.clear")}>
													<Button
														className={formAction === "create" ? "buttonStyle__14" : ""}
														onClick={resetForm}
														disabled={formAction === "edit"}
														icon={<FontAwesomeIcon icon={faX} />}
													/>
												</Tooltip>
											</div>
										</div>
									}
									key='action'
									render={(_: any, record: BankActionInterface) => (
										<div>
											{formAction === "edit" && record.id === action.id ? (
												<div className='generalStyles__flexCenter'>
													<div>
														<Tooltip title={t("general.save")}>
															<Button
																className='buttonStyle__10'
																disabled={!checkFormIsValid()}
																onClick={onClickSave}
																icon={<FontAwesomeIcon icon={faSave} />}
															/>
														</Tooltip>
													</div>
													<div className='generalStyles__mlFix'>
														<Tooltip title={t("general.cancel")}>
															<Button
																className='buttonStyle__14'
																onClick={resetForm}
																icon={<FontAwesomeIcon icon={faX} />}
															/>
														</Tooltip>
													</div>
												</div>
											) : (
												<BankActionsTableActions selectedItem={record} />
											)}
										</div>
									)}
								/>
							</ColumnGroup>
						</Table>
					</>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t("organizationManagement.listNotAvailableOrganizations")}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostAction}
				isSuccess={isSuccessPostAction}
				requestType='POST'
				error={errorPostAction}
				name='Action'
			/>
			<GlobalAlert2
				isError={isErrorPutAction}
				isSuccess={isSuccessPutAction}
				requestType='PUT'
				error={errorPutAction}
				name='Action'
			/>
		</div>
	);
}

export default BankActionsTable;
