import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
import JSZip from "jszip";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalDownloadOffsetsConfigurationFile } from "../../../redux/configurationFiles/configurationFilesSlice";
import { useLazyGetConfigurationQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// UTILS
import {
	getOffsetsCountByConfiguration,
	buildOffsetsTypeDataObject,
	buildOffsetsFullTableData,
	buildOffsetsRangeTableData,
} from "../../../utils/utils";

// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function OffsetsDownloadConfiguration() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalDownloadOffsetsConfigurationFile, configurationFile } = useSelector(
		(state: any) => state.configurationFile
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [getConfiguration, { data, isLoading: isLoadingGetConfig }] = useLazyGetConfigurationQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleCloseModal = () => {
		dispatch(updateOpenModalDownloadOffsetsConfigurationFile(false));
	};

	const downloadCSV = async (csvData: any, fileType: string) => {
		const zip = new JSZip();
		csvData.map((csv: any) => {
			// Convert data to CSV format
			const headers = Object.keys(csv.data[0]);
			const csvContent =
				// eslint-disable-next-line prefer-template
				headers.join(",") + "\n" + csv.data.map((row: any) => headers.map((field) => row[field]).join(",")).join("\n");
			// Save csv in zip file
			zip.file(`${csv.name.replaceAll("/", "-")}-${configurationFile.name.replaceAll("/", "-")}.csv`, csvContent);
			return true;
		});
		const zipBlob = await zip.generateAsync({ type: "blob" });
		// Create a link element and trigger download
		const link = document.createElement("a");
		link.href = URL.createObjectURL(zipBlob);
		link.download = `${fileType}-${configurationFile.name.replaceAll("/", "-")}.zip`;
		document.body.appendChild(link);
		link.click();
		// Clean up
		document.body.removeChild(link);
		URL.revokeObjectURL(link.href);
		handleCloseModal();
	};

	const generateTableOffsetsFullTable = (fullTableColumns: any, fullTableData: any, response: any) => {
		const newArr: any = [];
		const copy = structuredClone(fullTableData);
		copy.map((item: any) => {
			const newItem: any = {
				dataBlock: response.data_block_type.name,
				Offset: item.name,
				Byte: `${item.byte}`,
				TotalByes: getOffsetsCountByConfiguration(item.name, response.offsets),
			};
			fullTableColumns.map((item2: any) => {
				newItem[item2.liftName] = item[`${item2.liftName}${item.id}`] || "--";
				return true;
			});
			newArr.push(newItem);
			return true;
		});
		return newArr;
	};

	const generateTableOffsetsRangeTable = (rangeTableColumns: any, rangeTableData: any, response: any) => {
		const newArr: any = [];
		const copy = structuredClone(rangeTableData);
		const dataBlockName = response.data_block_type.name;
		copy.map((item: any) => {
			const newItem: any = {
				Lift: item.liftName,
			};
			rangeTableColumns.map((item2: any) => {
				item.statusArr.map((item3: any) => {
					if (`${item2.name}-0` === item3.key) {
						newItem[item2.name] = item3.offset;
					}
					return true;
				});
				return true;
			});
			newArr.push(newItem);
			return true;
		});
		return { name: `OFFSETS-${dataBlockName}`, data: newArr };
	};

	const buildOffsetsObjectTable = (dataBlocks: any) => {
		const newArrRangeTable: any = [];
		const newArrFullTable: any = [];
		dataBlocks.map((dataBlock: any) => {
			if (dataBlock && dataBlock.data_block_type && dataBlock.offset_types && dataBlock.offsets) {
				const offsetsData = buildOffsetsTypeDataObject(dataBlock);
				// Generate range table data
				const offsetsRangeTableData = buildOffsetsRangeTableData(offsetsData);
				newArrRangeTable.push(
					generateTableOffsetsRangeTable(offsetsRangeTableData[0], offsetsRangeTableData[1], dataBlock)
				);
				// Generate full table data
				const offsetsFullTableData = buildOffsetsFullTableData([], offsetsData.offsets);
				if (offsetsFullTableData && dataBlock.data_block_type.name) {
					newArrFullTable.push(
						generateTableOffsetsFullTable(offsetsFullTableData[0], offsetsFullTableData[1], dataBlock)
					);
				}
			}
			return true;
		});
		const xlsxData = [...newArrRangeTable, { name: "OFFSETS-FULL TABLE", data: [].concat(...newArrFullTable) }];
		downloadCSV(xlsxData, "OFFSETS");
	};

	const initData = async () => {
		const token = await GETJwtToken();
		getConfiguration({
			token,
			configurationId: configurationFile.id,
		});
	};

	const onClickDownloadReport = async () => {
		initData();
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.data && data.data.data_blocks) {
			buildOffsetsObjectTable(data.data.data_blocks);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={handleCloseModal}
			closable={false}
			onCancel={handleCloseModal}
			open={openModalDownloadOffsetsConfigurationFile}
			footer={null}
		>
			<Spin spinning={isLoadingGetConfig}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<div className='mt-3 mb-3'>
							Please note that reports download are audited. By continuing, you confirm that you are following your
							organizations best practices for exporting, using, and retaining data.{" "}
						</div>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("general.close")}
							</Button>
							<Button htmlType='submit' className='buttonStyle__2 generalStyles__mlFix' onClick={onClickDownloadReport}>
								{t("general.download")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
		</Modal>
	);
}

export default OffsetsDownloadConfiguration;
