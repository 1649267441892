import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Popover, notification } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleInfo, faWarning } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ActionButtonPopover from "./ActionButtonPopover";
// INTERFACES
import { ActionButtonInterface } from "../../../interfaces/Actions.interface";
import { RootState } from "../../../app/store";

function ActionButtonTimed({ action, liftId, serverId, carService, layout, isControlPanel }: ActionButtonInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const TIMEOUT = 3;
	const CERO_COMMANDS = ["0"];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loading, setLoading] = useState(false);
	const [counter, setCounter] = useState(0); // Initial counter value
	const [lastCommandSent, setLastCommandSent] = useState<string>("ON");
	const [checkIfCommandChange, setCheckIfCommandChange] = useState<boolean>(false);
	const [localCarService, setLocalCarService] = useState<string>("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { liftSelected, socketConnection, layoutSelected } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postCommand,
		{
			isSuccess: isSuccessPostCommand,
			isError: isErrorPostCommand,
			isLoading: isLoadingPostCommand,
			error: errorPostCommand,
			reset: resetPostCommand,
		},
	] = usePostMonitoringCommandMutation();
	const [
		postCommandEmulator,
		{
			isSuccess: isSuccessPostEmulatorCommand,
			isError: isErrorPostEmulatorCommand,
			isLoading: isLoadingPostEmulatorCommand,
			reset: resetPostEmulatorCommand,
		},
	] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickCommand = async (command: string, cmdOff: boolean, type: string, message: string) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				cmd_adapter: isControlPanel ? layoutSelected.typeChannel === "ADAPTER" : layout.typeChannel === "ADAPTER",
				cmd_off: cmdOff,
				lift_id: liftId,
				short_msg: command,
				type: type || "CONTROL",
				message,
			},
			serverId,
		};
		postCommand(BODY);
	};

	const onClickCommandEmulator = async (commands: any) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				lift_id: liftId,
				short_msg: {
					bank_id: isControlPanel ? layoutSelected.id : layout.id,
					lift_id: liftSelected.id,
					bankName: isControlPanel ? layoutSelected.name : layout.name,
					liftName: liftSelected.name,
					msg: {
						type: "CARSERVICE",
						message: "",
						short_msg: commands,
					},
				},
			},
			serverId,
		};
		postCommandEmulator(BODY);
	};

	const onClickTimedButton = () => {
		setLastCommandSent("ON");
		if (socketConnection === "EMULATOR") {
			onClickCommandEmulator(action.action.valid_states);
		} else {
			onClickCommand(
				action && action.action && action.action.cmd ? action.action.cmd : "",
				false,
				action && action.action && action.action.type ? action.action.type : "",
				action && action.action && action.action.label ? action.action.label : ""
			);
		}
	};

	const launchTimed = () => {
		setLoading(true);
		let count = action.timer; // Counter starts from 5
		setCounter(count); // Update counter state
		const countdownInterval = setInterval(() => {
			// eslint-disable-next-line operator-assignment
			count = count - 1;
			setCounter(count);
			if (count === 0) {
				clearInterval(countdownInterval);
				setLoading(false);
			}
		}, 1000); // Update counter every second
		setTimeout(() => {
			clearInterval(countdownInterval);
			setLoading(false);
			setLastCommandSent("OFF");
			if (socketConnection === "EMULATOR") {
				onClickCommandEmulator(action.toggle_action.valid_states);
			} else if (
				action &&
				action.toggle_action &&
				action.toggle_action.cmd &&
				CERO_COMMANDS.includes(action.toggle_action.cmd)
			) {
				onClickCommand(
					action && action.toggle_action && action.toggle_action.cmd ? action.toggle_action.cmd : "",
					true,
					action && action.toggle_action && action.toggle_action.type ? action.toggle_action.type : "",
					action && action.toggle_action && action.toggle_action.label ? action.toggle_action.label : ""
				);
			} else {
				onClickCommand(
					action && action.action && action.action.cmd ? action.action.cmd : "",
					true,
					action && action.action && action.action.type ? action.action.type : "",
					action && action.action && action.action.label ? action.action.label : ""
				);
			}
		}, action.timer * 1000); // Stop countdown and loading indicator after 5 seconds
	};

	const launchMessage = (errorCommand: boolean) => {
		let errorMessage = "Error Command";
		if (errorCommand && errorPostCommand) {
			const copyError: any = structuredClone(errorPostCommand);
			errorMessage = copyError.data.details || copyError.data.message || "Error";
		}
		notification.open({
			message: (
				<h5 className={errorCommand ? "generalStyles__error" : "generalStyles__success"}>
					{lastCommandSent === "ON" ? action.action.label : action.toggle_action.label}
				</h5>
			),
			description: errorCommand ? (
				errorMessage
			) : (
				<span>
					{t("general.command")} {action.action.cmd} {t("general.sentSuccessfully")}
				</span>
			),
			placement: "top",
			className: `monitoring__notification__${theme}`,
			icon: (
				<FontAwesomeIcon
					icon={errorCommand ? faWarning : faCheckCircle}
					fontSize={18}
					className={`monitoring__modalSettings__icon ${
						errorCommand ? "generalStyles__error" : "generalStyles__success"
					}`}
				/>
			),
		});
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		resetPostCommand();
		resetPostEmulatorCommand();
		if (isSuccessPostEmulatorCommand || isSuccessPostCommand) {
			if (lastCommandSent === "ON") {
				launchTimed();
			} else if (lastCommandSent === "OFF") {
				setTimeout(() => {
					setCheckIfCommandChange(true);
				}, TIMEOUT * 1000);
			}
		}
		if (isErrorPostEmulatorCommand || isErrorPostCommand) {
			launchMessage(true);
		}
	}, [isSuccessPostEmulatorCommand, isErrorPostEmulatorCommand, isSuccessPostCommand, isErrorPostCommand]);

	useEffect(() => {
		setLocalCarService(carService);
	}, [carService]);

	useEffect(() => {
		if (checkIfCommandChange) {
			setCheckIfCommandChange(false);
			if (
				lastCommandSent === "OFF" &&
				((action && action.toggle_action && action.toggle_action.valid_states.includes(localCarService)) ||
					(action && action.action && action.action.valid_states && action.action.valid_states.length === 0))
			) {
				launchMessage(false);
			} else {
				launchMessage(true);
			}
		}
	}, [checkIfCommandChange]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flexCenter' style={{ marginBottom: "10px" }}>
			<div>
				<Popover
					content={
						<ActionButtonPopover
							action={action.action}
							toggleAction={action.toggle_action}
							type='TURNBACK'
							timer1={action.timer}
							timer2={0}
						/>
					}
					title={loading ? action.toggle_action.tooltip : action.action.tooltip}
				>
					<FontAwesomeIcon icon={faCircleInfo} fontSize={18} className='generalStyles__warning generalStyles__mrFix' />
				</Popover>
			</div>
			<div>
				<Button
					className={
						(action && action.action && action.action.valid_states.includes(localCarService)) ||
						isLoadingPostCommand ||
						isLoadingPostEmulatorCommand ||
						loading
							? "buttonStyle__basicButtonActionInactive"
							: "buttonStyle__basicButtonAction"
					}
					loading={loading || isLoadingPostEmulatorCommand || isLoadingPostCommand}
					onClick={onClickTimedButton}
					disabled={
						(action &&
							action.action &&
							action.action.valid_states &&
							action.action.valid_states.includes(localCarService)) ||
						loading ||
						isLoadingPostCommand ||
						isLoadingPostEmulatorCommand
					}
					style={{ backgroundColor: `${action.color}`, width: "210px" }}
				>
					{loading ? (
						<>
							{action.toggle_action.cmd}
							... {counter}s
						</>
					) : (
						<span className='generalStyles__textEllipsis' style={{ maxWidth: "170px" }}>
							{action.action.label}
						</span>
					)}
				</Button>
			</div>
		</div>
	);
}

export default ActionButtonTimed;
