import React, { useEffect, useState } from "react";
// XLSX
import * as XLSX from "xlsx";
// MOMENT
import moment from "moment";
// ANT DESIGN COMPONENTS
import { Table, InputNumber, Button, Spin, Upload, message } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSave, faTableCellsLarge, faTools, faUpload } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePutOffsetsBulkMutation,
	useLazyGetOffsetsByDataBlockQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// UTILS
import {
	getTableRowClass,
	getOffsetsCountByConfiguration,
	generateExcelAndDownloadDefinitions,
	buildOffsetsTypeDataObject,
	buildOffsetsFullTableData,
	buildOffsetsRangeTableData,
} from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";

function OffsetsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [rangeTableColumns, setRangeTableColumns] = useState<any>([]);
	const [rangeTableData, setRangeTableData] = useState<any>([]);
	const [fullTableData, setFullTableData] = useState<any>([]);
	const [fullTableColumns, setFullTableColumns] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { configurationFile } = useSelector((state: any) => state.configurationFile);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [putOffsetsBulk, { isSuccess, isError, error, isLoading: isLoadingPutOffsetsBulk, reset }] =
		usePutOffsetsBulkMutation();
	const [getOffsetsByDataBlock, { data: dataOffsetsByDataBlock, isLoading: isLoadingOffsetsByDataBlock }] =
		useLazyGetOffsetsByDataBlockQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onClickAutoFillOffsets = (rangeTableData_: any) => {
		const clone = structuredClone(rangeTableData_);
		for (let x = 0; x < clone.length; x += 1) {
			let counter = 0;
			for (let y = 0; y < clone[x].statusArr.length; y += 1) {
				if (clone[x].statusArr[y].keyIndex === 0) {
					counter = clone[x].statusArr[y].offset;
				} else if (clone[x].statusArr[y].keyIndex !== 0) {
					if (counter > 0) counter += 1;
				}
				const newOffset = counter === 0 ? 0 : counter;
				clone[x].statusArr[y].offset = newOffset;
			}
		}
		const offsetsFullTableData = buildOffsetsFullTableData(clone, []);
		setFullTableColumns(offsetsFullTableData[0]);
		setFullTableData(offsetsFullTableData[1]);
	};

	const onChangeOffsetFirstValue = (newValue: number, offset: string, liftName: string) => {
		const clone = structuredClone(rangeTableData);
		if (clone && clone.length > 0) {
			for (let x = 0; x < clone.length; x += 1) {
				// Change specific name
				for (let y = 0; y < clone[x].statusArr.length; y += 1) {
					// Save new offset value
					if (clone[x].liftName === liftName && clone[x].statusArr[y].key === offset) {
						clone[x].statusArr[y].offset = newValue;
					}
					// Check if offset is valid
					if (clone[x].statusArr[y].offset === null) {
						clone[x].statusArr[y].isValid = false;
					} else {
						clone[x].statusArr[y].isValid = true;
					}
				}
			}
			setRangeTableData(clone);
			onClickAutoFillOffsets(clone);
		}
	};

	const buildDataFromImportXlsx = (jsonData: any) => {
		const copy = structuredClone(rangeTableData);
		jsonData.map((row: any) => {
			const index = copy.findIndex((x: any) => x.liftName === row.Lift);
			if (index >= 0) {
				Object.entries(row).forEach(([key, value]) => {
					const index2 = copy[index].statusArr.findIndex((x: any) => x.key === `${key}-0`);
					if (index2 >= 0) {
						copy[index].statusArr[index2].offset = value;
					}
				});
			}
			return true;
		});
		setRangeTableData(copy);
		onClickAutoFillOffsets(copy);
	};

	const onClickSave = async () => {
		if (data) {
			const copy: any = structuredClone(data.offsets);
			rangeTableData.map((item: any) => {
				if (item && item.statusArr) {
					item.statusArr.map((item2: any) => {
						if (item2 && item2.keyIndex === 0) {
							// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
							// update offset value
							copy.map((offset: any, copyIndex: number) => {
								delete copy[copyIndex].definitions;
								// const myArray = item2.name.split("-");
								const extractNumber = item2.name.match(/(\d+)/);
								const parcialName = item2.name.replace(extractNumber[0].toString(), "");
								const name = parcialName.slice(0, -1);
								if (item2.keyIndex === 0 && item.liftnumber === offset.position && name === offset.offset_type.name) {
									copy[copyIndex].initial_value = item2.offset;
									copy[copyIndex].last_value = item2.offset + item2.byteCount - 1;
								}
								return true;
							});
						}
						return true;
					});
				}
				return true;
			});
			// Save bulk offsets
			const token = await GETJwtToken();
			const BODY = {
				token,
				body: { offsets: copy },
			};
			putOffsetsBulk(BODY);
		}
	};

	const initData = async () => {
		const token = await GETJwtToken();
		getOffsetsByDataBlock({
			token,
			dataBlockID: configurationFile.data_block.id,
		});
	};

	const onClickDownloadExcel = () => {
		const currentDateTime = moment().format("YYYY_MMM_DD");
		const configurationName = configurationFile.name;
		// eslint-disable-next-line no-useless-escape
		const reportName = `offsets_${configurationName.replace(/[\/\\:\*\?"<>\|\s]/g, "_")}_${currentDateTime}`;
		const newArr: any = [];
		const copy = structuredClone(rangeTableData);
		copy.map((item: any) => {
			const newItem: any = {
				Lift: item.liftName,
			};
			rangeTableColumns.map((item2: any) => {
				item.statusArr.map((item3: any) => {
					if (`${item2.name}-0` === item3.key) {
						newItem[item2.name] = item3.offset;
					}
					return true;
				});
				return true;
			});
			newArr.push(newItem);
			return true;
		});
		generateExcelAndDownloadDefinitions(newArr, reportName);
	};

	const handleUpload = (file: File) => {
		showLoading();
		const reader = new FileReader();
		reader.onload = (e) => {
			try {
				const binaryStr = e.target?.result;
				const workbook = XLSX.read(binaryStr, { type: "binary" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet);
				message.success("File uploaded and data processed successfully!");
				buildDataFromImportXlsx(jsonData);
			} catch (error2) {
				message.error("Error reading the Excel file. Please try again.");
			}
		};

		reader.readAsBinaryString(file); // Read the file as binary
		return false; // Prevent default upload behavior
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, [configurationFile]);

	useEffect(() => {
		if (dataOffsetsByDataBlock && dataOffsetsByDataBlock.data && data) {
			showLoading();
			const offsetsData = buildOffsetsTypeDataObject(dataOffsetsByDataBlock.data);
			setData(offsetsData);
			// generate and build full table
			const offsetsFullTableData = buildOffsetsFullTableData([], offsetsData.offsets);
			setFullTableColumns(offsetsFullTableData[0]);
			setFullTableData(offsetsFullTableData[1]);
			// Generate and build range table
			const offsetsRangeTableData = buildOffsetsRangeTableData(offsetsData);
			setRangeTableColumns(offsetsRangeTableData[0]);
			setRangeTableData(offsetsRangeTableData[1]);
			// Reset
			reset();
		}
	}, [dataOffsetsByDataBlock]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingOffsetsByDataBlock}>
			{/** SWITCH TABLE */}
			<div className='generalStyles__pageHeader' style={{ display: "flex", justifyContent: "space-between" }}>
				<div />
				<div className='generalStyles__flex'>
					<div>
						<Button
							className='buttonStyle__13  generalStyles__mlFix buttonStyle__normal'
							style={{ marginTop: "-5px" }}
							onClick={onClickDownloadExcel}
							icon={<FontAwesomeIcon className='generalStyles__mrFix' icon={faDownload} />}
						>
							Download Excel File
						</Button>
					</div>
					<div>
						<Upload accept='.xlsx, .xls' beforeUpload={handleUpload} showUploadList={false}>
							<Button
								className='buttonStyle__20  generalStyles__mlFix buttonStyle__normal'
								style={{ marginTop: "-5px" }}
								icon={<FontAwesomeIcon className='generalStyles__mrFix' icon={faUpload} />}
							>
								Upload Excel File
							</Button>
						</Upload>
					</div>
					<div>
						<Button
							icon={<FontAwesomeIcon className='generalStyles__mrFix' icon={faSave} />}
							className='buttonStyle__17 generalStyles__mlFix buttonStyle__normal'
							style={{ marginTop: "-5px" }}
							onClick={onClickSave}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
			<div className='mt-3'>
				<DrawerRowDivider
					icon={faTools}
					title={`Offsets Configuration / ${configurationFile && configurationFile.name ? configurationFile.name : ""}
				`}
				/>
				<div>
					{/** ---------------------------------------------------- */}
					{/** RANGE TABLE */}
					{rangeTableData && rangeTableData.length > 0 && (
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							dataSource={rangeTableData}
							pagination={false}
							scroll={{ x: "100%" }}
							loading={isLoading || isLoadingPutOffsetsBulk}
							size='small'
							rowKey={(record) => record.key}
						>
							{/** NAME COLUMN */}
							<Column
								title='Lift'
								dataIndex='liftName'
								key='liftName'
								fixed
								className='generalStyles__tableHeaderLink '
								width='150px'
								render={(text) => (
									<div>
										<span>{text}</span>
									</div>
								)}
							/>
							{/** DINAMIC COLUMN */}
							{rangeTableColumns.length > 0 && (
								<>
									{rangeTableColumns.map((item: any) => (
										<Column
											title={item.name}
											dataIndex={item.name}
											key={item.key}
											className='configuration__columnEllipsis'
											render={(text, record: any) => (
												<>
													{record.statusArr.map((item2: any, index2: number) => (
														<div key={index2}>
															{`${item.name}-0` === item2.key && (
																<>
																	<InputNumber
																		style={{ width: "100%" }}
																		precision={0}
																		value={item2.offset}
																		onChange={(e) => {
																			onChangeOffsetFirstValue(e, item2.key, record.liftName);
																		}}
																	/>
																</>
															)}
														</div>
													))}
												</>
											)}
										/>
									))}
								</>
							)}
						</Table>
					)}
				</div>
				<DrawerRowDivider icon={faTableCellsLarge} title='Offsets Preview' />
				<div>
					{/** ---------------------------------------------------- */}
					{/** FULL TABLE */}
					{fullTableData && fullTableData.length > 0 && (
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							dataSource={fullTableData}
							pagination={false}
							loading={isLoading || isLoadingPutOffsetsBulk}
							size='small'
							rowKey={(record) => record.id}
							scroll={{ x: "100%" }}
						>
							{/** NAME COLUMN */}
							<Column
								title='Offset'
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink'
								fixed
								width='150px'
								render={(text) => (
									<div>
										<div className='configuration__columnEllipsis'>
											<span>{text}</span>
										</div>
									</div>
								)}
							/>
							{/** BYTE NUMBER */}
							<Column
								title='Byte'
								dataIndex='byte'
								key='byte'
								width='100px'
								render={(text, record: any) => (
									<div>
										<div>
											<span>
												{text} / {record.byteCount || getOffsetsCountByConfiguration(record.name, data.offsets)}
											</span>
										</div>
									</div>
								)}
							/>
							{/** DINAMIC COLUMN */}
							{fullTableColumns.length > 0 && (
								<>
									{fullTableColumns.map((item: any) => (
										<Column
											title={item.liftName}
											dataIndex={item.liftName}
											key={item.key}
											width='125px'
											className='configuration__columnEllipsis'
											render={(text, record: any) => (
												<div>
													<div className='generalStyles__mlFix'>{record[`${item.liftName}${record.id}`]}</div>
												</div>
											)}
										/>
									))}
								</>
							)}
						</Table>
					)}
				</div>
				<br />
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='PUT' error={error} name='Configuration' />
		</Spin>
	);
}

export default OffsetsTable;
