import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Drawer } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faCube, faGear, faUserCircle } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalCreateEditNewConfigurationFile } from "../../../redux/configurationFiles/configurationFilesSlice";
import {
	usePostConfigurationMutation,
	usePostDataBlockCompleteMutation,
	usePutDataBlockCompleteMutation,
	useLazyGetOffsetsByDataBlockQuery,
	usePutOffsetsBulkMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ConfigurationFilesSelectOffsets from "./ConfigurationFilesSelectOffsets";
import GlobalAlert2 from "../../home/GlobalAlert2";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import FormInputText from "../../components/form/FormInputText";
import FormInputNumber from "../../components/form/FormInputNumber";
import FormSelectLiftType from "../../components/form/FormSelectLiftType";
import FormSwitchActiveInactive from "../../components/form/FormSwitchActiveInactive";
import FormSelectVendors from "../../components/form/FormSelectVendors";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	ConfigurationInterface,
	OffsetsTypesInterface,
	ConfigurationOffsetsInterface,
	OffsetsByDataBlockInterface,
	DataBlockOffsetType,
} from "../../../interfaces/Configurations.interface";
// UTILS
import { getOffsetsCountByConfiguration, getRegisterTypeConfiguration } from "../../../utils/utils";

function ConfigurationFilesCreateEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);
	const DATA_BLOCK = "CAR DATA";

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [configuration, setConfiguration] = useState<ConfigurationInterface>({
		lift_type: "ELEVATOR",
		lifts: 1,
		name: "",
		rear_door: true,
		stops: 2,
		vendor_id: "",
	});
	const [offsetsTypes, setOffsetsTypes] = useState<OffsetsTypesInterface[]>([]);
	const [selectedItemsListOffsetsTypes, setSelectedItemsListOffsetsTypes] = useState<string[]>([]);
	const [editDataBlockId, setEditDataBlockId] = useState<string>("");
	const [offsetsByDataBlock, setOffsetsByDataBlock] = useState<OffsetsByDataBlockInterface>({
		configuration: {
			lift_type: "ELEVATOR",
			name: "",
			data_blocks: {},
			vendor: {
				name: "",
				configurations: {},
				id: "",
			},
			lifts: 1,
			stops: 2,
			id: "",
			vendor_id: "",
			rear_door: false,
		},
		banks: [],
		offsets: [],
		offset_types: [],
		data_block_type: {
			name: "",
			id: "",
		},
		id: "",
		configuration_id: "",
		data_block_type_id: "",
	});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalCreateEditNewConfigurationFile, formCreateEdit, configurationFile } = useSelector(
		(state: RootState) => state.configurationFile
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostConfiguration,
		{
			isSuccess: isSuccessPostConfiguration,
			isLoading: isLoadingPostConfiguration,
			isError: isErrorPostConfiguration,
			error: errorPostConfiguration,
		},
	] = usePostConfigurationMutation();
	const [postDataBlock] = usePostDataBlockCompleteMutation();
	const [putDataBlock, { isSuccess: isSuccessPutDataBlock, isError: isErrorPutDataBlock, error: errorPutDataBlock }] =
		usePutDataBlockCompleteMutation();
	const [triggerGetOffsetsByDataBlock, { data: dataGetOffsetsByDataBlock, isLoading: isLoadingOffsetsByDataBlock }] =
		useLazyGetOffsetsByDataBlockQuery();
	const [
		triggerPutOffsetsBulk,
		{ isSuccess: isSuccessPutOffsetsBulk, isError: isErrorPutOffsetsBulk, error: errorPutOffsetsBulk },
	] = usePutOffsetsBulkMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const cleanData = () => {
		setConfiguration({
			lift_type: "ELEVATOR",
			lifts: 1,
			name: "",
			rear_door: true,
			stops: 2,
			vendor_id: "",
		});
		setOffsetsTypes([]);
	};

	const onChangeFormValue = (newValue: any, variableName: string) => {
		const copy: ConfigurationInterface = structuredClone(configuration);
		copy[variableName] = newValue;
		setConfiguration(copy);
	};

	const onClickClose = () => {
		dispatch(updateOpenModalCreateEditNewConfigurationFile(false));
		cleanData();
	};

	const getDataBlockId = () => {
		const env = process.env.REACT_APP_ENVIRONMENT;
		if (env === "DEVELOPMENT" || env === "LOCAL") {
			return "d89ecb95-d4c0-4ce6-b6a2-7f4fa0487e0f";
		}
		if (env === "PRODUCTION" || env === "OFFLINE") {
			return "364e0374-319e-4e70-8c95-15296c7f8c6a";
		}
		if (env === "STAGING") {
			return "20168dcc-80dd-49c2-aa4d-ebe36dd38418";
		}
		return "";
	};

	const buildPostConfigurationBlocks = (configurationID: string) => {
		const offsets: ConfigurationOffsetsInterface[] = [];
		for (let index = 1; index <= configuration.lifts; index += 1) {
			offsetsTypes.map((offsetType: OffsetsTypesInterface, indexOffset: number) => {
				offsets.push({
					byte_count: offsetType.byte_count,
					register_type: offsetType.register_type,
					data_block_id: getDataBlockId(),
					definitions: [],
					definitions2delete: [],
					exceptions: "",
					initial_value: 0,
					last_value: 0,
					type: offsetType.name,
					name: `Car ${indexOffset + 1}`,
					offset_type_id: offsetType.id,
					position: index,
				});
				return true;
			});
		}
		return {
			banks: [],
			configuration_id: configurationID,
			data_block_type_id: getDataBlockId(),
			definitions: [],
			offsets,
		};
	};

	const buildPutOffsetsBulk = async (putOffsetsOldItems: any) => {
		const updatedArray = offsetsByDataBlock.offsets.map((offset: any) => {
			const match = putOffsetsOldItems.find((source: any) => source.id === offset.offset_type_id);
			return {
				...offset,
				definitions: [],
				byte_count: match ? match.byte_count : offset.byte_count,
				register_type: match ? match.register_type : offset.register_type,
			};
		});
		// PUT Offsets Bulk
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: { offsets: updatedArray },
		};

		triggerPutOffsetsBulk(BODY);
	};

	const buildPutConfigurationBlocks = (configurationID: string) => {
		const offsets: ConfigurationOffsetsInterface[] = [];
		const putOffsetsNewItems: OffsetsTypesInterface[] = offsetsTypes.filter(
			(item) => !offsetsByDataBlock.offset_types.some((toRemove: any) => toRemove.id === item.id)
		);
		const putOffsetsOldItems: OffsetsTypesInterface[] = offsetsTypes.filter((item) =>
			offsetsByDataBlock.offset_types.some((toRemove: any) => toRemove.id === item.id)
		);
		if (putOffsetsOldItems.length > 0) {
			buildPutOffsetsBulk(putOffsetsOldItems);
		}
		// PUT OFFSETS TYPES NEW VALUES
		for (let index = 1; index <= configuration.lifts; index += 1) {
			putOffsetsNewItems.map((offsetType: OffsetsTypesInterface, indexOffset: number) => {
				offsets.push({
					byte_count: offsetType.byte_count,
					register_type: offsetType.register_type,
					data_block_id: editDataBlockId,
					definitions: [],
					definitions2delete: [],
					exceptions: "",
					initial_value: 0,
					last_value: 0,
					type: offsetType.name,
					name: `Car ${indexOffset + 1}`,
					offset_type_id: offsetType.id,
					position: index,
				});
				return true;
			});
		}
		return {
			banks: [],
			configuration_id: configurationID,
			data_block_id: editDataBlockId,
			definitions: [],
			offsets,
		};
	};

	const triggerPostDataBlock = async (configurationID: string) => {
		const token = await GETJwtToken();
		// POST method
		const postConfigurationBlocks = buildPostConfigurationBlocks(configurationID);
		if (postConfigurationBlocks.offsets.length > 0) {
			const POST_BODY = {
				token,
				body: postConfigurationBlocks,
			};
			postDataBlock(POST_BODY);
		}
		onClickClose();
	};

	const triggerPutDataBlock = async (configurationID: string) => {
		const token = await GETJwtToken();
		// PUT method
		const putConfigurationBlocks = buildPutConfigurationBlocks(configurationID);
		if (putConfigurationBlocks.offsets.length > 0) {
			const PUT_BODY = {
				token,
				body: putConfigurationBlocks,
			};
			putDataBlock(PUT_BODY);
		}
		onClickClose();
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: configuration,
		};
		// POST / CREATE
		if (formCreateEdit === "CREATE") {
			const response = await triggerPostConfiguration(BODY).unwrap();
			if (response && response.data && response.data.id) {
				triggerPostDataBlock(response.data.id);
			}
		}
		// PUT / EDIT
		else if (formCreateEdit === "EDIT") {
			triggerPutDataBlock(configurationFile.id);
		}
	};

	const onChangeOffsets = (newData: OffsetsTypesInterface[]) => {
		setOffsetsTypes(newData);
	};

	const onChangeSelectedItemsListOffsetsTypes = (newData: string[]) => {
		setSelectedItemsListOffsetsTypes(newData);
	};

	const editBuildOffsetsTypes = (responseDefinitions: OffsetsByDataBlockInterface) => {
		if (configurationFile.data_blocks[0].id) {
			setEditDataBlockId(configurationFile.data_blocks[0].id);
		}
		const newDataOffsets: OffsetsTypesInterface[] = [];
		const newDataListOffsetsSelected: string[] = [];
		responseDefinitions.offset_types.map((offsetType: DataBlockOffsetType) => {
			newDataOffsets.push({
				byte_count: getOffsetsCountByConfiguration(offsetType.name, responseDefinitions.offsets),
				register_type: getRegisterTypeConfiguration(offsetType.name, responseDefinitions.offsets),
				id: offsetType.id,
				name: offsetType.name,
			});
			newDataListOffsetsSelected.push(offsetType.name);
			return true;
		});
		setSelectedItemsListOffsetsTypes(newDataListOffsetsSelected);
		setOffsetsTypes(newDataOffsets);
	};

	const initDataGetOffsetsByDataBlock = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsByDataBlock({
			token,
			dataBlockID: configurationFile.data_blocks[0].id,
		});
	};

	const onFillData = () => {
		setConfiguration({
			lift_type: configurationFile.lift_type,
			lifts: configurationFile.lifts,
			name: configurationFile.name,
			rear_door: configurationFile.rear_door,
			stops: configurationFile.stops,
			vendor_id: configurationFile.vendor_id,
		});
	};

	const disableSaveButton = () => {
		let disableButton = false;
		if (configuration.vendor_id === "" || configuration.name === "" || offsetsTypes.length === 0) {
			disableButton = true;
		}
		return disableButton;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalCreateEditNewConfigurationFile) {
			if (formCreateEdit === "EDIT") {
				initDataGetOffsetsByDataBlock();
				onFillData();
			}
		}
	}, [openModalCreateEditNewConfigurationFile]);

	useEffect(() => {
		if (dataGetOffsetsByDataBlock && dataGetOffsetsByDataBlock.data) {
			setOffsetsByDataBlock(dataGetOffsetsByDataBlock.data);
			editBuildOffsetsTypes(dataGetOffsetsByDataBlock.data);
		}
	}, [dataGetOffsetsByDataBlock]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='650'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateEditNewConfigurationFile}
			footer={
				<div className='container generalStyles__flex'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={disableSaveButton()} />
					</div>
				</div>
			}
		>
			<div>
				<Spin spinning={isLoadingPostConfiguration || isLoadingOffsetsByDataBlock}>
					{/** ---------------------------------------------------- */}
					{/** COMPONENT / DRAWER DIVIDER / CONFIGURATION FORM */}
					<DrawerRowDivider
						title={formCreateEdit === "CREATE" ? t("general.createConfiguration") : t("general.editConfiguration")}
						icon={faGear}
					/>
					<div className='container'>
						<div className={`drawer__box__${theme}`}>
							<div className='row'>
								{/** COMPONENTS FORM - NAME */}
								<FormInputText
									inputValue={configuration.name}
									inputLabelName={t("general.name")}
									inputOnChange={onChangeFormValue}
									inputVariableName='name'
									isRequired
									disabledInput={false}
								/>
								{/** COMPONENTS FORM - SELECT LIFT TYPE */}
								<FormSelectLiftType
									inputValue={configuration.lift_type}
									inputOnChange={onChangeFormValue}
									inputLabelName='Lift Type'
									inputVariableName='lift_type'
								/>
								{/** COMPONENTS FORM - NUMBER OF LIFTS */}
								<FormInputNumber
									inputValue={configuration.lifts}
									inputLabelName='Lifts'
									inputOnChange={onChangeFormValue}
									inputVariableName='lifts'
								/>
								{configuration.lift_type === "ELEVATOR" && (
									<>
										{/** COMPONENTS FORM - FLOOR COUNT */}
										<FormInputNumber
											inputValue={configuration.stops}
											inputLabelName={t("general.stops")}
											inputOnChange={onChangeFormValue}
											inputVariableName='stops'
										/>
									</>
								)}
								{/** REAR DOORS */}
								{configuration.lift_type === "ELEVATOR" && (
									<>
										{/** COMPONENTS FORM - SWITCH REAR DOORS */}
										<FormSwitchActiveInactive
											inputValue={configuration.rear_door}
											inputLabelName={t("configuration.configurationFiles.rearDoors")}
											inputOnChange={onChangeFormValue}
											inputVariableName='rear_door'
										/>
									</>
								)}
							</div>
						</div>
					</div>
					<br />
					{/** ---------------------------------------------------- */}
					{/** COMPONENT / DRAWER DIVIDER / VENDOR */}
					<DrawerRowDivider title={t("general.vendor")} icon={faUserCircle} />
					<div className='container'>
						<div className={`drawer__box__${theme}`}>
							{/** COMPONENTS FORM - VENDOR */}
							<FormSelectVendors
								inputValue={configuration.vendor_id}
								inputOnChange={onChangeFormValue}
								inputLabelName={t("general.vendor")}
								inputVariableName='vendor_id'
							/>
						</div>
					</div>
					<br />
					{/** ---------------------------------------------------- */}
					{/** COMPONENT / DRAWER DIVIDER / DATA BLOCK */}
					<DrawerRowDivider title={t("general.dataBlock")} icon={faCube} />
					<div className='container'>
						<div className={`drawer__box__${theme}`}>
							{/** COMPONENTS FORM - DATA BLOCKS */}
							<FormInputText
								inputValue={DATA_BLOCK}
								inputLabelName={t("general.dataBlock")}
								inputOnChange={() => {}}
								inputVariableName='data_block'
								isRequired
								disabledInput
							/>
						</div>
					</div>
					<br />
					{/** ---------------------------------------------------- */}
					{/** COMPONENT / DRAWER DIVIDER / CONTROL DATA */}
					<div className='container'>
						<DrawerRowDivider title={DATA_BLOCK} icon={faGear} />
						<ConfigurationFilesSelectOffsets
							onChangeOffsets={onChangeOffsets}
							onChangeSelectedItemsListOffsetsTypes={onChangeSelectedItemsListOffsetsTypes}
							offsetsTypes={offsetsTypes}
							selectedItemsListOffsetsTypes={selectedItemsListOffsetsTypes}
							editDataBlockId={editDataBlockId}
						/>
					</div>
				</Spin>
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostConfiguration}
				isSuccess={isSuccessPostConfiguration}
				requestType='POST'
				error={errorPostConfiguration}
				name='Configuration'
			/>
			<GlobalAlert2
				isError={isErrorPutDataBlock}
				isSuccess={isSuccessPutDataBlock}
				requestType='PUT'
				error={errorPutDataBlock}
				name='Configuration'
			/>
			<GlobalAlert2
				isError={isErrorPutOffsetsBulk}
				isSuccess={isSuccessPutOffsetsBulk}
				requestType='PUT'
				error={errorPutOffsetsBulk}
				name='Offsets'
			/>
		</Drawer>
	);
}

export default ConfigurationFilesCreateEditForm;
